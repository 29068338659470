import { ColDef } from 'ag-grid-community';
import { useState } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import {
  Category,
  categoriesState,
  deleteCategoryCallback,
  getAllCategoriesCallback,
  getAllCategoriesWithSubcategories,
} from '@laborability/commons';
import { CategoryForm } from '../components/Forms';
import { GridImage } from '../components/GridImage';
import { ImageView } from '../components/ImageView';
import { modalState } from '../recoil/states/modalState';
import { useGrid } from '../hooks/useGrid';

export function CategoriesPage() {
  const setModal = useSetRecoilState(modalState);
  const getAllCategories = useRecoilCallback(getAllCategoriesCallback, []);
  const deleteCategory = useRecoilCallback(deleteCategoryCallback, []);

  const { rowData, handleFetchAll, handleDelete } = useGrid<Category>({
    state: categoriesState,
    onFetch: getAllCategories,
    onDelete: deleteCategory,
  });
  const { orderedCategories } = getAllCategoriesWithSubcategories(rowData);

  const [columnDefs, _] = useState<ColDef[]>([
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Categoria',
    },
    {
      field: 'parent_id',
      flex: 2,
      filter: false,
      resizable: false,
      headerName: 'Parent',
      valueFormatter: params =>
        params.api.getRowNode(params.data.parent_id)?.data.name ?? '-',
    },
    {
      field: 'description',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Descrizione',
    },
    {
      field: 'icon',
      filter: false,
      flex: 1,
      headerName: 'Icona',
      width: 100,
      cellRenderer: GridImage,
      cellRendererParams: {
        callback: async (data: Category) => {
          setModal(prev => ({
            ...prev,
            show: true,
            children: <ImageView image={data.icon ? data.icon : ''} />,
            title: 'Icona Categoria',
            subtitle1: '',
          }));
        },
      },
    },
    {
      field: 'image',
      filter: false,
      flex: 1,
      headerName: 'Immagine',
      width: 100,
      cellRenderer: GridImage,
      cellRendererParams: {
        callback: async (data: Category) => {
          setModal(prev => ({
            ...prev,
            show: true,
            children: <ImageView image={data?.image ?? ''} />,
            title: 'Immagine Categoria',
          }));
        },
      },
    },
  ]);

  return (
    <GridLayout<any>
      pageName="Categorie"
      columnDefs={columnDefs}
      rowData={orderedCategories}
      handleFetchAll={handleFetchAll}
      handleDelete={handleDelete}
      FormComponent={CategoryForm}
      recordNameAccessor={item => item.name ?? ''}
      rowHeight={100}
    />
  );
}
