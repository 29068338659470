import { createHashRouter, Navigate, RouterProvider } from 'react-router-dom';
import Layout from './components/Layout';
import NoPage404 from './pages/NoPage404';
import { BenefitsPage } from './pages/Benefits';
import { CategoriesPage } from './pages/Categories';
import { CompaniesPage } from './pages/Companies';
import { ContractsPage } from './pages/Contracts';
import { EntesPage } from './pages/Ente';
import { MisurePage } from './pages/Measure';
import { TagsPage } from './pages/Tags';
import { UsersPage } from './pages/Users';
import { Home } from './pages/Home';

export const APP_ROUTES = {
  HOME: 'home',
  REGISTERED_USER: 'registered-user',
  REGISTERED_TAGS: 'registered-tags',
  REGISTERED_CATEGORIES: 'registered-categories',
  REGISTERED_BENEFITS: 'registered-benefits',
  REGISTERED_COMPANIES: 'registered-companies',
  REGISTERED_MEASURES: 'registered-measures',
  REGISTERED_ENTES: 'registered-enti',
  REGISTERED_CONTRACTS: 'registered-contracts',
};

export default function AppRoutes() {
  const router = createHashRouter([
    {
      path: '/',
      element: <Layout />,
      children: [
        { index: true, path: '/', element: <Navigate to={APP_ROUTES.HOME} /> },
        {
          path: APP_ROUTES.HOME,
          element: <Home />,
        },
        {
          path: APP_ROUTES.REGISTERED_USER,
          element: <UsersPage />,
          handle: {
            page_title: 'Registrazione utenti',
          },
        },
        {
          path: APP_ROUTES.REGISTERED_TAGS,
          element: <TagsPage />,
          handle: {
            page_title: 'Registrazione tags',
          },
        },
        {
          path: APP_ROUTES.REGISTERED_CATEGORIES,
          element: <CategoriesPage />,
          handle: {
            page_title: 'Registrazione categorie',
          },
        },
        {
          path: APP_ROUTES.REGISTERED_BENEFITS,
          element: <BenefitsPage />,
          handle: {
            page_title: 'Registrazione benefits',
          },
        },
        {
          path: APP_ROUTES.REGISTERED_COMPANIES,
          element: <CompaniesPage />,
          handle: {
            page_title: 'Registrazione aziende',
          },
        },
        {
          path: APP_ROUTES.REGISTERED_MEASURES,
          element: <MisurePage />,
          handle: {
            page_title: 'Registrazione misure',
          },
        },
        {
          path: APP_ROUTES.REGISTERED_ENTES,
          element: <EntesPage />,
          handle: {
            page_title: 'Registrazione enti',
          },
        },
        {
          path: APP_ROUTES.REGISTERED_CONTRACTS,
          element: <ContractsPage />,
          handle: {
            page_title: 'Registrazione contratti',
          },
        },
        {
          path: '/*',
          element: <NoPage404 />,
          handle: {
            page_title: 'Pagina non trovata',
          },
        },
      ],
    },
    { index: true, path: '/*', element: <Navigate to={APP_ROUTES.HOME} /> },
  ]);

  return <RouterProvider router={router} />;
}
