import {
  COLORS,
  AppTheme,
  IconCrossComponent,
  IconsSize,
  IconsStyle,
  KeycloakContext,
} from '@laborability/components';
import { Alert, Box, Modal, Snackbar, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import styled, { ThemeProvider } from 'styled-components';
import { modalState } from '../recoil/states/modalState';
import ApplicationBar from './ApplicationBar';
import { snackBarState } from '@laborability/commons';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  maxWidth: '800px',
  border: '1px solid #000',
  padding: '24px 16px 24px 16px',
  backgroundColor: COLORS.getInstance().WHITE,
};

const StyledBox = styled(Box)`
  cursor: pointer;
`;

const Layout = () => {
  const { keycloak } = useContext(KeycloakContext);

  const theme = useMemo(() => {
    return AppTheme;
  }, []);

  const [{ show, children, title }, setModal] = useRecoilState(modalState);
  const [
    { duration, show: openSnackbar, severity, message, code },
    setSnackBar,
  ] = useRecoilState(snackBarState);

  useEffect(() => {
    if (code && code === 401) keycloak?.logout();
  }, [code]);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar(prev => ({
      ...prev,
      show: false,
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={show}
        onClose={() => {
          setModal(prev => ({
            ...prev,
            show: false,
          }));
        }}
      >
        <Stack style={style}>
          <Stack
            sx={{ borderBottom: '1px solid #DDDDDD' }}
            direction="column"
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              sx={{ width: 1 }}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Typography
                sx={{ flex: 1, textAlign: 'center' }}
                fontSize={'20px'}
                fontWeight={'700'}
              >
                {title}
              </Typography>
              <StyledBox
                onClick={() =>
                  setModal(prev => ({
                    ...prev,
                    show: false,
                  }))
                }
              >
                <IconCrossComponent
                  color="#000"
                  size={IconsSize.MEDIUM}
                  style={IconsStyle.OUTLINE}
                ></IconCrossComponent>
              </StyledBox>
            </Stack>
          </Stack>

          <Box>{children}</Box>
        </Stack>
      </Modal>
      <Box sx={{ display: 'flex' }}>
        <ApplicationBar />
        <Box component="main" sx={{ flexGrow: 1, p: 3, mt: '55px' }}>
          <Outlet />
        </Box>
      </Box>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={duration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleClose} severity={severity} variant="filled">
          {message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default Layout;
