import { Box, Button, Stack, Typography } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { modalState } from '../recoil/states/modalState';

export const DeleteAlert: React.FC<{
  message: string;
  onDelete: (id: number) => Promise<void>;
  entityId: number;
}> = ({ message, onDelete, entityId }) => {
  const setModal = useSetRecoilState(modalState);
  return (
    <>
      <Stack display={'flex'} flexDirection={'column'}>
        <Typography sx={{ padding: '16px', fontSize: '1.2rem' }}>
          {message}
        </Typography>
        <Box
          sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setModal(prev => ({
                ...prev,
                show: false,
              }));
            }}
          >
            Annulla
          </Button>
          <Button
            sx={{ marginLeft: '8px' }}
            variant="contained"
            color="primary"
            onClick={async () => {
              await onDelete(entityId);
            }}
          >
            Conferma
          </Button>
        </Box>
      </Stack>
    </>
  );
};
