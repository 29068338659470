import { ImageLogoComponent, KeycloakContext } from '@laborability/components';
import {
  CorporateFareOutlined,
  HomeOutlined,
  PeopleOutline,
} from '@mui/icons-material';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArticleIcon from '@mui/icons-material/Article';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FoundationIcon from '@mui/icons-material/Foundation';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import StraightenOutlinedIcon from '@mui/icons-material/StraightenOutlined';
import VolunteerActivismOutlinedIcon from '@mui/icons-material/VolunteerActivismOutlined';
import { Menu, MenuItem, Tooltip } from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer, { DrawerProps } from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { CSSObject, Theme, styled } from '@mui/material/styles';
import * as React from 'react';
import { useLocation, useMatches, useNavigate } from 'react-router-dom';
import { APP_ROUTES } from '../Routes';

const drawerWidth = 240;
enum DRAWER_STATE {
  OPEN = 'open',
  CLOSED = 'close',
  REDUCED = 'reduced',
}

type HandlePageTitle = {
  page_title: string;
};

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('desktop')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const closedAllMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `0`,
});

const DrawerHeader = styled('div')<{ justifyContent: string }>(
  ({ theme, justifyContent }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent,
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }),
);

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps & { drawerstate: DRAWER_STATE }>(
  ({ theme, open, drawerstate }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(drawerstate === DRAWER_STATE.OPEN && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    ...(drawerstate === DRAWER_STATE.REDUCED && {
      marginLeft: '65px',
      width: `calc(100% - 65px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }),
);

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})<DrawerProps & { drawerstate: DRAWER_STATE }>(({
  theme,
  open,
  drawerstate,
}) => {
  console.log('@@@p', open);
  return {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(drawerstate === DRAWER_STATE.OPEN && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(drawerstate === DRAWER_STATE.REDUCED && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
    ...(drawerstate === DRAWER_STATE.CLOSED && {
      ...closedAllMixin(theme),
      '& .MuiDrawer-paper': closedAllMixin(theme),
    }),
  };
});

export default function ApplicationBar() {
  const { keycloak } = React.useContext(KeycloakContext);
  const location = useLocation();
  const [open, setOpen] = React.useState(DRAWER_STATE.OPEN);
  const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
  const navigate = useNavigate();
  const userRef = React.useRef<{ user: '' }>();
  const handleDrawerOpen = () => {
    let state = DRAWER_STATE.OPEN;

    if (open === DRAWER_STATE.OPEN || open === DRAWER_STATE.REDUCED) {
      state = DRAWER_STATE.CLOSED;
    }

    setOpen(state);
  };

  const handleDrawerOpenFromReduced = () => {
    setOpen(DRAWER_STATE.OPEN);
  };

  const handleDrawerClose = () => {
    setOpen(DRAWER_STATE.CLOSED);
  };

  const handleDrawerReduced = () => {
    setOpen(DRAWER_STATE.REDUCED);
  };

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null,
  );

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (e: any, setting: string) => {
    if (setting === 'Logout') keycloak?.logout();
    setAnchorElUser(null);
  };

  const matches = useMatches();
  const pageTitle = React.useMemo(() => {
    const pHandlePageTitle = matches.find(
      match =>
        'handle' in match &&
        match.handle &&
        (match.handle as HandlePageTitle).page_title,
    );
    const t = pHandlePageTitle
      ? (pHandlePageTitle.handle as HandlePageTitle).page_title
      : 'BO App';

    document.title = t;
    return t;
  }, [matches]);

  React.useEffect(() => {
    try {
      userRef.current = JSON.parse(localStorage.getItem('lbt_user') + '').user;
    } catch (error) {}
  }, []);
  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open === DRAWER_STATE.OPEN}
        drawerstate={open}
        sx={{ marginLeft: '45px' }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
            }}
          >
            <MenuIcon />
          </IconButton>

          <Box
            sx={{
              mr: 2,
              flexGrow: 1,
            }}
          >
            <Typography variant="h6" noWrap component="div">
              {pageTitle}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <AccountCircleOutlinedIcon />{' '}
                <Typography
                  textAlign="center"
                  marginLeft={'8px'}
                  marginRight={'8px'}
                >
                  {userRef?.current?.user}
                </Typography>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map(setting => (
                <MenuItem
                  key={setting}
                  role={setting}
                  onClick={(e: any) => handleCloseUserMenu(e, setting)}
                >
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        drawerstate={open}
        variant="permanent"
        open={open === DRAWER_STATE.OPEN}
        onClose={handleDrawerClose}
      >
        <DrawerHeader justifyContent="flex-start">
          <ImageLogoComponent height="24px" width="182px" />
        </DrawerHeader>{' '}
        <Divider />
        <List>
          {[
            ['Dashboard', APP_ROUTES.HOME, <HomeOutlined />],
            ['Utenti', APP_ROUTES.REGISTERED_USER, <PeopleOutline />],
            [
              'Aziende',
              APP_ROUTES.REGISTERED_COMPANIES,
              <CorporateFareOutlined />,
            ],
            [
              'Misure',
              APP_ROUTES.REGISTERED_MEASURES,
              <StraightenOutlinedIcon />,
            ],
            [
              'Categorie',
              APP_ROUTES.REGISTERED_CATEGORIES,
              <CategoryOutlinedIcon />,
            ],
            ['Tag', APP_ROUTES.REGISTERED_TAGS, <LocalOfferOutlinedIcon />],
            ['Enti', APP_ROUTES.REGISTERED_ENTES, <FoundationIcon />],
            [
              'Benefit',
              'registered-benefits',
              <VolunteerActivismOutlinedIcon />,
            ],
            ['Contratti', APP_ROUTES.REGISTERED_CONTRACTS, <ArticleIcon />],
          ].map((text, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  backgroundColor:
                    `/${text[1]}` === location.pathname ? 'silver' : null,
                }}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  navigate(`/${text[1]}`);
                }}
              >
                <ListItemIcon>{text[2]}</ListItemIcon>
                <ListItemText>{text[0]}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        {/* <List>
          {['All mail', 'Trash', 'Spam'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                sx={{
                  minHeight: 48,

                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>{' '} */}
        <Divider />
        <DrawerHeader justifyContent="flex-end">
          <IconButton
            onClick={
              open === DRAWER_STATE.REDUCED
                ? handleDrawerOpenFromReduced
                : handleDrawerReduced
            }
          >
            {open === DRAWER_STATE.REDUCED ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
      </Drawer>
    </>
  );
}
