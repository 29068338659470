import { Grid, Stack, Typography } from '@mui/material';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  categoriesState,
  getAllCategoriesCallback,
  postCategoryCallback,
  putCategoryCallback,
  acceptedImageType,
  getBase64,
  removeNullishOrEmptyFields,
  getCategoryByIdCallback,
  categoryState,
  CategoryClass,
  categoriesLoaderState,
  categoryLoaderState,
} from '@laborability/commons';
import React, { useEffect } from 'react';
import ModalForm from '../ModalForm';
import { LBTSelect, LBTTextField } from '@laborability/components';

type Props = {
  id?: number;
};

export function CategoryForm({ id }: Props) {
  const postCategory = useRecoilCallback(postCategoryCallback, []);
  const putCategory = useRecoilCallback(putCategoryCallback, []);
  const getCategory = useRecoilCallback(getCategoryByIdCallback, []);
  const getAllCategories = useRecoilCallback(getAllCategoriesCallback, []);
  const [current, setCurrent] = useRecoilState(categoryState);
  const categories = useRecoilValue(categoriesState);

  const categoriesLoader = useRecoilValue(categoriesLoaderState);
  const categoryLoader = useRecoilValue(categoryLoaderState);
  const isLoading = categoriesLoader || categoryLoader;

  const resetFormState = () => {
    setCurrent(new CategoryClass());
  };

  useEffect(() => {
    getAllCategories();
  }, []);

  useEffect(() => {
    if (id) getCategory({ id });
    else resetFormState();
  }, [id]);

  return (
    <ModalForm
      defaultValues={new CategoryClass()}
      isLoading={isLoading}
      currentValues={current}
      validation={values => {
        return {
          ...(values.name === ''
            ? { name: 'Il nome della categoria è obbligatorio' }
            : {}),
        };
      }}
      handleSubmit={async values => {
        if (values.id)
          return await putCategory({
            id: values.id,
            ...removeNullishOrEmptyFields(values),
          });
        return await postCategory(removeNullishOrEmptyFields(values));
      }}
      resetGridState={getAllCategories}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleChange, handleBlur, setFieldValue }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              label="Nome"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              required
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Descrizione"
              type="text"
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item mobile={12}>
            <Stack flexDirection={'row'} display={'flex'}>
              <Typography
                display={'flex'}
                flex={0.5}
                alignItems={'center'}
                justifyContent={'left'}
              >
                Icona
              </Typography>
              <LBTTextField
                sx={{ flex: 1 }}
                type="file"
                inputProps={acceptedImageType}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const file = event.target.files && event.target.files[0];
                  if (!file) return;
                  getBase64(file).then(data => setFieldValue('icon', data));
                }}
              />
            </Stack>
            <img src={values.icon} style={{ maxHeight: '100px' }}></img>
          </Grid>
          <Grid item mobile={12}>
            <Stack flexDirection={'row'} display={'flex'}>
              <Typography
                display={'flex'}
                flex={0.5}
                alignItems={'center'}
                justifyContent={'left'}
              >
                Immagine
              </Typography>
              <LBTTextField
                sx={{ flex: 1 }}
                type="file"
                inputProps={acceptedImageType}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const file = event.target.files && event.target.files[0];
                  if (!file) return;
                  getBase64(file).then(data => setFieldValue('image', data));
                }}
              />
            </Stack>
            <img src={values.image} style={{ maxHeight: '100px' }}></img>
          </Grid>
          <Grid item mobile={12}>
            <LBTSelect
              id="parent-select"
              name="parent"
              label="Categoria padre"
              value={values.parent_id}
              items={categories
                .filter(item => !item.parent_id)
                .map(item => ({
                  id: item.id as number,
                  name: item.name as string,
                }))}
              handleChange={e => {
                setFieldValue('parent_id', e);
              }}
              onBlur={handleBlur}
            />
          </Grid>
        </>
      )}
    </ModalForm>
  );
}
