import { atom } from 'recoil';
import { getUUID, Modal } from '@laborability/commons';

export const modalState = atom<Modal>({
  key: getUUID(),
  default: {
    show: false,
    children: undefined,
    title: '',
    subtitle1: '',
  },
});
