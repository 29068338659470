import { removeNullishOrEmptyFields } from '@laborability/commons';
import { COLORS } from '@laborability/components';
import { Button, Grid, Stack } from '@mui/material';
import { Formik, FormikProps, FormikValues } from 'formik';
import { ReactElement, ReactNode } from 'react';
import Loader from './Loader';

interface Props<T> {
  defaultValues: T;
  currentValues?: T;
  isLoading: boolean;
  validation: (values: T) => Partial<{ [K in keyof T]: string }>;
  handleSubmit: (values: T) => Promise<any>;
  resetGridState: (params?: any) => Promise<any>;
  resetFormState: () => void;
  children: (params: FormikProps<T>) => ReactNode;
  customButtons?: ((params: FormikProps<T>) => ReactElement)[];
}

export default function ModalForm<T extends FormikValues>({
  defaultValues,
  currentValues,
  validation,
  handleSubmit,
  resetGridState,
  resetFormState,
  children,
  customButtons,
  isLoading,
}: Props<T>) {
  const isEditForm = !!currentValues?.id;

  if (isLoading) return <Loader />;

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...defaultValues,
        /* serve perché i null dal be rompono i campi di input, 
        sovrascrivo la classe con solo i dati che mi interessano */
        ...removeNullishOrEmptyFields(currentValues),
      }}
      validate={validation}
      onSubmit={async (values, { setSubmitting }) => {
        await handleSubmit(values);
        setSubmitting(false);
        await resetGridState();
      }}
    >
      {params => (
        <form onSubmit={params.handleSubmit} style={{ padding: '16px' }}>
          <Grid
            container
            spacing={2}
            style={{ overflow: 'auto', maxHeight: '70vh' }}
          >
            {children(params)}
          </Grid>
          <Stack
            justifyContent={isEditForm ? 'space-between' : 'right'}
            direction="row"
            display="flex"
            paddingTop={2}
            bgcolor={COLORS.getInstance().WHITE}
          >
            {isEditForm && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={params.isSubmitting}
                sx={{ marginRight: '8px' }}
                onClick={() => {
                  params.resetForm();
                  resetFormState();
                }}
              >
                Nuovo
              </Button>
            )}
            {customButtons ? (
              <Stack flexDirection="row">
                {customButtons.map(item => {
                  return item(params);
                })}
              </Stack>
            ) : (
              <Button
                type="submit"
                variant="contained"
                disabled={params.isSubmitting}
              >
                {isEditForm ? 'Modifica' : 'Registra'}
              </Button>
            )}
          </Stack>
        </form>
      )}
    </Formik>
  );
}
