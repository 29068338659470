import './App.css';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the grid
import AppRoutes from './Routes';
import {
  useKeycloak,
  useDayjs,
  UserRole,
  currentUserState,
  useStartup,
} from '@laborability/commons';
import { KeycloakContext, LBTLabel } from '@laborability/components';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

function App() {
  useDayjs();
  const { keycloak, init } = useKeycloak();
  const { onStartup, isCompletedStartup } = useStartup();
  const currentUser = useRecoilValue(currentUserState);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (keycloak?.tokenParsed) onStartup();
  }, [keycloak?.tokenParsed]);

  if (
    isCompletedStartup &&
    currentUser.role !== UserRole.ADMIN &&
    currentUser.role !== UserRole.COMPANY
  ) {
    keycloak?.logout();
    keycloak?.login();
  }

  return (
    <KeycloakContext.Provider value={{ keycloak }}>
      {isCompletedStartup ? (
        <AppRoutes />
      ) : (
        <LBTLabel height="100vh" alignContent="center" fontSize="25px">
          CARICAMENTO...
        </LBTLabel>
      )}
    </KeycloakContext.Provider>
  );
}

export default App;
