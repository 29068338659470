import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  postBenefitCallback,
  putBenefitCallback,
  removeNullishOrEmptyFields,
  BenefitClass,
  benefitState,
  getBenefitByIdCallback,
  getAllBenefitsCallback,
  benefitLoaderState,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { LBTTextField } from '@laborability/components';

type Props = {
  id?: number;
};

export function BenefitForm({ id }: Props) {
  const postBenefit = useRecoilCallback(postBenefitCallback, []);
  const putBenefit = useRecoilCallback(putBenefitCallback, []);
  const getBenefit = useRecoilCallback(getBenefitByIdCallback, []);
  const getAllBenefit = useRecoilCallback(getAllBenefitsCallback, []);
  const [current, setCurrent] = useRecoilState(benefitState);
  const benefitLoader = useRecoilValue(benefitLoaderState);

  const resetFormState = () => {
    setCurrent(new BenefitClass());
  };

  useEffect(() => {
    if (id) getBenefit({ id });
    else resetFormState();
  }, [id]);

  return (
    <ModalForm
      defaultValues={new BenefitClass()}
      isLoading={benefitLoader}
      currentValues={current}
      validation={values => {
        return {
          ...(values.name === ''
            ? { name: 'Il nome del Benefit è obbligatorio' }
            : {}),
        };
      }}
      handleSubmit={async values => {
        if (values.id)
          return await putBenefit({
            id: values.id,
            ...removeNullishOrEmptyFields(values),
          });
        return await postBenefit(removeNullishOrEmptyFields(values));
      }}
      resetGridState={getAllBenefit}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleChange, handleBlur }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              label="Nome"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              required
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Descrizione"
              type="text"
              name="description"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.description}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
        </>
      )}
    </ModalForm>
  );
}
