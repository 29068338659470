import { Delete, Edit } from '@mui/icons-material';
import { ICellRendererParams } from 'ag-grid-community';

export enum GRID_BUTTON_TYPE {
  EDIT = 'edit',
  DELETE = 'delete',
}

export const GriButton = (props: ICellRendererParams) => {
  const buttonClicked = (evt: any) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (
      'callback' in props.colDef?.cellRendererParams &&
      typeof props.colDef?.cellRendererParams.callback === 'function'
    ) {
      props.colDef?.cellRendererParams.callback(props.node.data);
    } else {
      console.error('@@@ callback not found');
    }

    // if (props.colDef?.cellRendererParams.type === GRID_BUTTON_TYPE.DELETE) {
    //   evt.preventDefault();
    //   evt.stopPropagation();
    // } else if ((props.column as any).colId === GRID_BUTTON_TYPE.EDIT) {

    // }
  };

  return (
    <>
      <span>
        {(props.column as any).colId === 'edit' && (
          <div style={{ cursor: 'pointer' }} onClick={e => buttonClicked(e)}>
            <Edit></Edit>
          </div>
        )}
        {(props.column as any).colId === 'delete' && (
          <div style={{ cursor: 'pointer' }} onClick={e => buttonClicked(e)}>
            <Delete></Delete>
          </div>
        )}
      </span>
    </>
  );
};
