import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import {
  postEnteCallback,
  putEnteCallback,
  getAllContractsCallback,
  contractsState,
  removeNullishOrEmptyFields,
  EnteClass,
  agencyState,
  getEnteByIdCallback,
  getAllEnteCallback,
  agencyLoaderState,
  contractsLoaderState,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { LBTSelect, LBTTextField } from '@laborability/components';

interface Props {
  id?: number;
}

export function EnteForm({ id }: Props) {
  const postEnte = useRecoilCallback(postEnteCallback, []);
  const putEnte = useRecoilCallback(putEnteCallback, []);
  const getEnte = useRecoilCallback(getEnteByIdCallback, []);
  const getAllEnte = useRecoilCallback(getAllEnteCallback, []);
  const getAllContracts = useRecoilCallback(getAllContractsCallback, []);
  const [current, setCurrent] = useRecoilState(agencyState);
  const contracts = useRecoilValue(contractsState);

  const contractsLoader = useRecoilValue(contractsLoaderState);
  const agencyLoader = useRecoilValue(agencyLoaderState);
  const isLoading = contractsLoader || agencyLoader;

  const resetFormState = () => {
    setCurrent(new EnteClass());
  };

  useEffect(() => {
    getAllContracts();
  }, []);

  useEffect(() => {
    if (id) getEnte({ id });
    else resetFormState();
  }, [id]);

  return (
    <ModalForm
      defaultValues={new EnteClass()}
      isLoading={isLoading}
      currentValues={current}
      validation={values => {
        return {
          ...(values.name === ''
            ? { name: 'Il nome del Ente è obbligatorio' }
            : {}),
          ...(!values.is_public_agency && !values.contract_id
            ? {
                contract_id:
                  "Il contratto è obbligatorio se l'ente non è pubblico",
              }
            : {}),
        };
      }}
      handleSubmit={async values => {
        if (values.id)
          return await putEnte({
            id: values.id,
            ...removeNullishOrEmptyFields(values),
          });
        return await postEnte(removeNullishOrEmptyFields(values));
      }}
      resetGridState={getAllEnte}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleChange, handleBlur, setFieldValue }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              label="Nome"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              required
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item mobile={9}>
            <LBTSelect
              id="contract-select"
              name="contract"
              value={values.contract_id}
              items={contracts.map(c => ({
                id: c.id as number,
                name: c.name as string,
              }))}
              label="Contratto"
              handleChange={e => {
                setFieldValue('contract_id', e);
              }}
              error={!!errors.contract_id}
              onBlur={handleBlur}
              helperText={errors.contract_id}
              required={!values.is_public_agency}
            />
          </Grid>
          <Grid item mobile={3}>
            <LBTSelect
              id="is_public_agency-select"
              name="is_public_agency"
              value={Number(values.is_public_agency)}
              label="Ente pubblico"
              items={[
                { id: 1, name: 'Sì' },
                { id: 0, name: 'No' },
              ]}
              handleChange={e => {
                setFieldValue('is_public_agency', !!e);
              }}
              required
              onBlur={handleBlur}
              sx={{ minWidth: 0 }}
            />
          </Grid>
        </>
      )}
    </ModalForm>
  );
}
