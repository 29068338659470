import { Grid, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import {
  getAllCompanyConfigurationCallback,
  getAllContractsCallback,
  getAllEnteCallback,
  getComuniByProvinceIdCallback,
  postCompanyCallback,
  postCompanyConfigurationCallback,
  putCompanyCallback,
  Company,
  regionsState,
  provincesState,
  municipalitiesState,
  contractsState,
  agenciesState,
  acceptedImageType,
  getBase64,
  removeNullishOrEmptyFields,
  CompanyClass,
  getAllCompaniesCallback,
  getCompanyByIdCallback,
  companyState,
  agenciesLoaderState,
  companyLoaderState,
  contractsLoaderState,
  companyConfigsLoaderState,
  REGEX_REALM_NAME,
  companyConfigsState,
  putCompanyConfigurationCallback,
} from '@laborability/commons';
import ModalForm from '../ModalForm';
import { useRecoilCallback, useRecoilState, useRecoilValue } from 'recoil';
import { COLORS, LBTSelect, LBTTextField } from '@laborability/components';

type Props = {
  id?: number;
};

export function CompanyForm({ id }: Props) {
  const postCompany = useRecoilCallback(postCompanyCallback, []);
  const postCompanyConfig = useRecoilCallback(
    postCompanyConfigurationCallback,
    [],
  );
  const putCompanyConfig = useRecoilCallback(
    putCompanyConfigurationCallback,
    [],
  );
  const putCompany = useRecoilCallback(putCompanyCallback, []);
  const getAllCompanyConfiguration = useRecoilCallback(
    getAllCompanyConfigurationCallback,
    [],
  );
  const getMunicipalities = useRecoilCallback(
    getComuniByProvinceIdCallback,
    [],
  );
  const getContracts = useRecoilCallback(getAllContractsCallback, []);
  const getAgencies = useRecoilCallback(getAllEnteCallback, []);
  const getCompany = useRecoilCallback(getCompanyByIdCallback, []);
  const getAllCompany = useRecoilCallback(getAllCompaniesCallback, []);
  const [current, setCurrent] = useRecoilState(companyState);
  const [companyConfigs, setCompantConfigs] =
    useRecoilState(companyConfigsState);
  const regions = useRecoilValue(regionsState);
  const provinces = useRecoilValue(provincesState);
  const municipalities = useRecoilValue(municipalitiesState);
  const contracts = useRecoilValue(contractsState);
  const agencies = useRecoilValue(agenciesState);

  const contractsLoader = useRecoilValue(contractsLoaderState);
  const agenciesLoader = useRecoilValue(agenciesLoaderState);
  const companyLoader = useRecoilValue(companyLoaderState);
  const companyConfigsLoader = useRecoilValue(companyConfigsLoaderState);
  const isLoading =
    contractsLoader || agenciesLoader || companyLoader || companyConfigsLoader;

  const resetFormState = () => {
    setCurrent(new CompanyClass());
    setCompantConfigs([]);
  };

  useEffect(() => {
    getContracts();
    getAgencies();
    if (current?.province_id) getMunicipalities({ id: current.province_id });
  }, []);

  useEffect(() => {
    if (id) {
      getCompany({ id });
      getAllCompanyConfiguration({
        id,
      });
    } else resetFormState();
  }, [id]);

  return (
    <ModalForm
      defaultValues={new CompanyClass()}
      isLoading={isLoading}
      currentValues={{
        ...current,
        ...companyConfigs.reduce(
          (obj, current) => ({
            ...obj,
            [current.key]: current.value,
          }),
          {},
        ),
      }}
      validation={values => {
        return {
          ...(values.name === ''
            ? { name: 'La ragione sociale è obbligatoria' }
            : {}),
          ...(values.address === ''
            ? { address: "L'indirizzo è obbligatorio" }
            : {}),
          ...(values.postal_code === '' ? { postal_code: 'Obbligatorio' } : {}),
          ...(!values.region_id ? { region: 'La regione è obbligatoria' } : {}),
          ...(!values.province_id
            ? { province: 'La provincia è obbligatoria' }
            : {}),
          ...(!values.city_id ? { city: 'La città è obbligatoria' } : {}),
          ...(values.country === ''
            ? { country: 'Il paese è obbligatorio' }
            : {}),
          ...(values.vat_number === ''
            ? { vat_number: 'La partita IVA è obbligatoria' }
            : {}),
          ...(values.email === '' ? { email: "L'email è obbligatoria" } : {}),
          ...(values.phone_number === ''
            ? { phone_number: 'Il numero di telefono è obbligatorio' }
            : {}),
          ...(!values.realm || values.realm === ''
            ? { realm: 'Il realm è obbligatorio' }
            : !REGEX_REALM_NAME.test(values.realm)
              ? { realm: 'Il realm non è valido, sono ammesse solo lettere' }
              : {}),
        };
      }}
      handleSubmit={async values => {
        const serverObj = Object.assign(
          {},
          removeNullishOrEmptyFields(values) as Company,
        );
        const logo = serverObj.logo;
        delete serverObj.logo;
        const primaryColor = serverObj.primary_color;
        delete serverObj.primary_color;
        const secondaryColor = serverObj.secondary_color;
        delete serverObj.secondary_color;
        const assocaf = serverObj.assocaf;
        delete serverObj.assocaf;
        const fifthAssignment = serverObj.fifth_assignment;
        delete serverObj.fifth_assignment;
        const tfr = serverObj.tfr;
        delete serverObj.tfr;
        const realm = serverObj.realm;
        delete serverObj.realm;

        let id = values.id;

        if (id) await putCompany({ id, ...serverObj });
        else id = (await postCompany(serverObj))?.data.id;

        const configArray = [
          { key: 'logo', value: logo },
          { key: 'primary_color', value: primaryColor },
          { key: 'secondary_color', value: secondaryColor },
          { key: 'assocaf', value: String(assocaf) },
          { key: 'fifth_assignment', value: String(fifthAssignment) },
          { key: 'tfr', value: String(tfr) },
          { key: 'realm', value: realm },
        ].reduce((prev: any, curr) => {
          if (!curr.value) return prev;
          return [
            ...prev,
            {
              ...curr,
              company_id: id,
            },
          ];
        }, []);

        if (values.id)
          await putCompanyConfig({ id: values.id, config: configArray });
        else if (id) await postCompanyConfig(configArray);
      }}
      resetGridState={getAllCompany}
      resetFormState={resetFormState}
    >
      {({ values, errors, handleChange, handleBlur, setFieldValue }) => (
        <>
          <Grid item mobile={12}>
            <LBTTextField
              label="Ragione sociale"
              type="text"
              name="name"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.name}
              required
              error={!!errors.name}
              helperText={errors.name}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Indirizzo"
              type="text"
              name="address"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.address}
              required
              error={!!errors.address}
              helperText={errors.address}
            />
          </Grid>
          <Grid item mobile={3}>
            <LBTSelect
              id="region_id-select"
              name="region_id"
              value={values.region_id}
              label="Regione"
              required
              items={regions.map(item => ({
                id: item.id as number,
                name: item.name as string,
              }))}
              handleChange={e => {
                setFieldValue('postal_code', '');
                setFieldValue('city_id', undefined);
                setFieldValue('province_id', undefined);
                setFieldValue('region_id', e);
              }}
              onBlur={handleBlur}
              error={!!errors.region_id}
              helperText={errors.region_id}
              sx={{ minWidth: 0 }}
            />
          </Grid>
          <Grid item mobile={3}>
            <LBTSelect
              id="province_id-select"
              name="province_id"
              value={values.province_id}
              label="Provincia"
              required
              items={provinces
                .filter(item => item.region_id === values.region_id)
                .map(item => ({
                  id: item.id as number,
                  name: item.name as string,
                }))}
              handleChange={e => {
                setFieldValue('postal_code', '');
                setFieldValue('city_id', undefined);
                setFieldValue('province_id', e);
                getMunicipalities({ id: e as number });
              }}
              onBlur={handleBlur}
              disabled={!values.region_id}
              error={!!errors.province_id}
              helperText={errors.province_id}
              sx={{ minWidth: 0 }}
            />
          </Grid>
          <Grid item mobile={4}>
            <LBTSelect
              id="city_id-select"
              name="city_id"
              value={values.city_id}
              label="Città"
              required
              items={municipalities.map(item => ({
                id: item.id as number,
                name: item.name as string,
              }))}
              handleChange={e => {
                setFieldValue(
                  'postal_code',
                  municipalities.find(item => item.id === e)?.postal_code ?? '',
                );
                setFieldValue('city_id', e);
              }}
              onBlur={handleBlur}
              error={!!errors.city_id}
              disabled={!values.province_id}
              helperText={errors.city_id}
              sx={{ minWidth: 0 }}
            />
          </Grid>
          <Grid item mobile={2}>
            <LBTTextField
              label="Codice postale"
              type="text"
              name="postal_code"
              onChange={() => {}}
              onBlur={handleBlur}
              value={values.postal_code}
              required
              disabled
              error={!!errors.postal_code}
              helperText={errors.postal_code}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Paese"
              type="text"
              name="country"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.country}
              required
              error={!!errors.country}
              helperText={errors.country}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Partita IVA"
              type="text"
              name="vat_number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.vat_number}
              required
              error={!!errors.vat_number}
              helperText={errors.vat_number}
            />
          </Grid>
          <Grid item mobile={6}>
            <LBTTextField
              label="Email"
              type="email"
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              required
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item mobile={6}>
            <LBTTextField
              label="Telefono"
              type="text"
              name="phone_number"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.phone_number}
              required
              error={!!errors.phone_number}
              helperText={errors.phone_number}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSelect
              id="agency_ids-select"
              name="agency_ids"
              value={values.agency_ids}
              label="Enti"
              multiple
              items={agencies.map(item => ({
                id: item.id as number,
                name: item.name as string,
              }))}
              handleChange={e => setFieldValue('agency_ids', e)}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSelect
              id="contract_ids-select"
              name="contract_ids"
              value={values.contract_ids}
              label="Contratti"
              multiple
              items={contracts.map(item => ({
                id: item.id as number,
                name: item.name as string,
              }))}
              handleChange={e => setFieldValue('contract_ids', e)}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item mobile={12}>
            <Stack flexDirection={'row'} display={'flex'}>
              <Typography
                display={'flex'}
                flex={0.5}
                alignItems={'center'}
                justifyContent={'left'}
              >
                Logo
              </Typography>
              <LBTTextField
                sx={{ flex: 1 }}
                type="file"
                inputProps={acceptedImageType}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  const file =
                    event &&
                    event.target &&
                    event.target.files &&
                    event.target.files[0];

                  if (file) {
                    getBase64(file as File).then(data =>
                      setFieldValue('logo', data),
                    );
                  }
                }}
              />
            </Stack>
            {current && (
              <img src={values.logo} style={{ maxHeight: '100px' }}></img>
            )}
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Nome realm"
              type="text"
              name="realm"
              disabled={!!companyConfigs.find(item => item.key === 'realm')}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.realm}
              required
              error={!!errors.realm}
              helperText={
                values.id ? 'Il realm non è modificabile' : errors.realm
              }
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Colore primario"
              type="color"
              name="primary_color"
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                values.primary_color
                  ? values.primary_color
                  : COLORS.getInstance().PRIMARY_MAIN_DEFAULT
              }
              required
              error={!!errors.primary_color}
              helperText={errors.primary_color}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTTextField
              label="Colore secondario"
              type="color"
              name="secondary_color"
              onChange={handleChange}
              onBlur={handleBlur}
              value={
                values.secondary_color
                  ? values.secondary_color
                  : COLORS.getInstance().SECONDARY_MAIN_DEFAULT
              }
              required
              error={!!errors.secondary_color}
              helperText={errors.secondary_color}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSelect
              id="assocaf-select"
              name="assocaf"
              value={String(values.assocaf)}
              label="Assocaf"
              required
              items={[
                { id: 'true', name: 'Sì' },
                { id: 'false', name: 'No' },
              ]}
              handleChange={e => {
                setFieldValue('assocaf', e);
              }}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSelect
              id="fifth_assignment-select"
              name="fifth_assignment"
              value={String(values.fifth_assignment)}
              label="Cessione del quinto"
              required
              items={[
                { id: 'true', name: 'Sì' },
                { id: 'false', name: 'No' },
              ]}
              handleChange={e => {
                setFieldValue('fifth_assignment', e);
              }}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item mobile={12}>
            <LBTSelect
              id="tfr-select"
              name="tfr"
              value={String(values.tfr)}
              label="TFR"
              required
              items={[
                { id: 'true', name: 'Sì' },
                { id: 'false', name: 'No' },
              ]}
              handleChange={e => {
                setFieldValue('tfr', e);
              }}
              onBlur={handleBlur}
            />
          </Grid>
        </>
      )}
    </ModalForm>
  );
}
