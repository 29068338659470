import {
  ColDef,
  ICellRendererParams,
  IDateFilterParams,
} from 'ag-grid-community';
import { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import GridLayout from '../components/GridLayout';
import { MeasureForm } from '../components/Forms';
import { useGrid } from '../hooks/useGrid';
import {
  deleteMisuraCallback,
  getAllMisureCallback,
  Misura,
  measuresState,
  Category,
} from '@laborability/commons';
import dayjs, { Dayjs } from 'dayjs';
import { Checkbox } from '@mui/material';

export function MisurePage() {
  const getAllMisuras = useRecoilCallback(getAllMisureCallback, []);
  const deleteMisura = useRecoilCallback(deleteMisuraCallback, []);

  const { rowData, handleFetchAll, handleDelete } = useGrid<Misura>({
    state: measuresState,
    onFetch: getAllMisuras,
    onDelete: deleteMisura,
  });

  const formattedRows = rowData.map(row => ({
    ...row,
    start_date: row?.start_date
      ? dayjs(row.start_date, 'DD-MM-YYYY HH:mm:ss')
      : dayjs(),
    end_date: row?.end_date
      ? dayjs(row.end_date, 'DD-MM-YYYY HH:mm:ss')
      : dayjs(),
  }));

  const sortDate = (valueA: Dayjs, valueB: Dayjs | Date) => {
    return valueA.diff(valueB, 'day');
  };

  const filterParams: IDateFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
      const dayCell = dayjs(cellValue, 'DD/MM/YYYY HH:mm:ss');
      if (!dayCell || !dayCell.isValid()) return -1;
      return sortDate(dayCell, filterLocalDateAtMidnight);
    },
    minValidYear: 1970,
    maxValidYear: 2100,
    inRangeFloatingFilterDateFormat: 'Do MMM YYYY',
  };

  const [columnDefs, _] = useState<ColDef[]>([
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Misura',
    },
    {
      field: 'description',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Descrizione',
    },
    {
      field: 'categories',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Categorie',
      valueGetter: params => {
        const subCategory: Category | undefined = params.data.categories.find(
          (category: Category) => category.parent_id,
        );
        if (subCategory) return subCategory.name;
        return params.data.categories?.length
          ? params.data.categories[0].name
          : '';
      },
    },
    {
      field: 'start_date',
      flex: 2,
      filter: 'agDateColumnFilter',
      headerName: 'Data inizio',
      comparator: sortDate,
      valueFormatter: params =>
        dayjs(params.value).format('DD/MM/YYYY HH:mm:ss'),
      filterParams,
    },
    {
      field: 'end_date',
      flex: 2,
      filter: 'agDateColumnFilter',
      headerName: 'Data fine',
      comparator: sortDate,
      valueFormatter: params =>
        dayjs(params.value).format('DD/MM/YYYY HH:mm:ss'),
      filterParams,
    },
    {
      field: 'draft',
      flex: 1,
      headerName: 'Bozza',
      filter: 'agNumberColumnFilter',
      filterParams: {
        maxNumConditions: 1,
        filterOptions: [
          {
            displayKey: 'Entrambi',
            displayName: 'Entrambi',
            predicate: () => true,
            numberOfInputs: 0,
          },
          {
            displayKey: 'Sì',
            displayName: 'Sì',
            // @ts-ignore
            predicate: (_, cellValue) => cellValue,
            numberOfInputs: 0,
          },
          {
            displayKey: 'No',
            displayName: 'No',
            // @ts-ignore
            predicate: (_, cellValue) => !cellValue,
            numberOfInputs: 0,
          },
        ],
        defaultOption: 'Entrambi',
      },
      cellRenderer: (params: ICellRendererParams) => (
        <Checkbox checked={params.value} disabled />
      ),
    },
  ]);

  return (
    <GridLayout<any>
      pageName="Misura"
      rowData={formattedRows}
      columnDefs={columnDefs}
      handleFetchAll={async () => {
        return await handleFetchAll({ limit: 100000 });
      }}
      handleDelete={handleDelete}
      FormComponent={MeasureForm}
      recordNameAccessor={item => item.name ?? ''}
    />
  );
}
