import { ColDef } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilValue, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { UserForm } from '../components/Forms';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useGrid } from '../hooks/useGrid';
import {
  User,
  getAllCompaniesCallback,
  getCompanyUsersCallback,
  snackBarState,
  currentUserState,
  deleteUserProfileCallback,
  usersState,
  companiesState,
} from '@laborability/commons';

export function UsersPage() {
  const [companyId, setCompanyId] = useState<number>(0);
  const companies = useRecoilValue(companiesState);
  const getAllCompanies = useRecoilCallback(getAllCompaniesCallback, []);
  const getCompanyUsers = useRecoilCallback(getCompanyUsersCallback, []);
  const deleteUser = useRecoilCallback(deleteUserProfileCallback, []);
  const setSnackBar = useSetRecoilState(snackBarState);
  const currentUser = useRecoilValue(currentUserState);

  const fetchUsers = async () => {
    if (companyId) return await getCompanyUsers({ id: companyId });
    else
      setSnackBar({
        show: true,
        message: "Seleziona un'azienda dalla lista per visualizarne gli utenti",
        severity: 'success',
        duration: 3000,
        code: 0,
      });
    return [];
  };

  const { rowData, handleFetchAll, handleDelete } = useGrid<User>({
    state: usersState,
    onFetch: fetchUsers,
    onDelete: deleteUser,
  });

  const [columnDefs, _] = useState<ColDef[]>([
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'email',
      flex: 1,
      filter: 'agTextColumnFilter',
      headerName: 'Email',
    },
    {
      field: 'first_name',
      flex: 1,
      filter: 'agTextColumnFilter',
      headerName: 'Nome',
    },
    {
      field: 'last_name',
      flex: 1,
      filter: 'agTextColumnFilter',
      headerName: 'Cognome',
    },
    {
      field: 'role',
      flex: 1,
      filter: 'agTextColumnFilter',
      headerName: 'Ruolo',
    },
  ]);

  useEffect(() => {
    getAllCompanies();
  }, []);

  useEffect(() => {
    companyId && handleFetchAll();
  }, [companyId]);

  return (
    <GridLayout<User>
      pageName="Utente"
      columnDefs={columnDefs}
      rowData={rowData}
      handleFetchAll={handleFetchAll}
      handleDelete={currentUser?.is_superuser ? handleDelete : undefined}
      FormComponent={UserForm}
      recordNameAccessor={item => `${item.first_name} ${item.last_name}`}
      hasAddNew={false}
      hasEdit={false}
      gridFilters={
        <FormControl>
          <InputLabel id="company-select-label">Seleziona azienda</InputLabel>
          <Select
            labelId="company-select-label"
            id="company-select"
            name="company"
            label="Seleziona azienda"
            fullWidth
            onChange={async e => {
              setCompanyId(e.target.value as number);
            }}
            sx={{ width: '300px' }}
          >
            {companies.map((company, index) => (
              <MenuItem key={index} value={company.id}>
                {company.name} - {company.vat_number}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
    />
  );
}
