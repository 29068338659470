import { Box, Button, Stack } from '@mui/material';
import { useSetRecoilState } from 'recoil';
import { modalState } from '../recoil/states/modalState';

export const ImageView: React.FC<{
  image: string;
}> = ({ image }) => {
  const setModal = useSetRecoilState(modalState);
  return (
    <>
      <Stack display={'flex'} flexDirection={'column'}>
        <img src={image} />
        <Box>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setModal(prev => ({
                ...prev,
                show: false,
              }));
            }}
          >
            Chiudi
          </Button>
        </Box>
      </Stack>
    </>
  );
};
