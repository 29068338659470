import { Stack, Button } from '@mui/material';
import {
  ColDef,
  GetRowIdParams,
  GridReadyEvent,
  IDatasource,
  RowSelectedEvent,
} from 'ag-grid-community';
import { ReactElement, useRef } from 'react';
import { modalState } from '../recoil/states/modalState';
import { useSetRecoilState } from 'recoil';
import { DeleteAlert } from './DeleteAlert';
import { GRID_BUTTON_TYPE, GriButton } from './GridButton';
import { AgGridReact } from 'ag-grid-react';

interface Props<T> {
  pageName: string;
  columnDefs: ColDef[];
  rowData?: T[];
  handleFetchAll: () => Promise<any>;
  handleDelete?: ({ id }: { id: number }) => Promise<boolean | null>;
  FormComponent: (params: { id?: number }) => ReactElement;
  recordNameAccessor?: (item: T) => string;
  gridFilters?: ReactElement;
  dataSource?: IDatasource;
  hasAddNew?: boolean;
  hasEdit?: boolean;
  // props per la grid
  [key: string]: any;
}

export default function GridLayout<T extends { id?: number }>({
  pageName,
  columnDefs,
  rowData,
  handleFetchAll,
  handleDelete,
  FormComponent,
  recordNameAccessor,
  dataSource,
  gridFilters,
  hasAddNew = true,
  hasEdit = true,
  ...rest
}: Props<T>) {
  const setModal = useSetRecoilState(modalState);
  const hasDelete = !!handleDelete;

  const callbackOnEdit = async (data: T) => {
    if (data && data.id) {
      setModal(prev => ({
        ...prev,
        show: true,
        children: <FormComponent id={data.id} />,
        title: `Modifica ${pageName}`,
        subtitle1: '',
      }));
    }
  };

  const callbackOnDelete = async (data: T) => {
    if (handleDelete && data && data.id) {
      setModal(prev => ({
        ...prev,
        show: true,
        children: (
          <DeleteAlert
            onDelete={async (id: number) => {
              if (await handleDelete({ id })) {
                handleFetchAll();
                setModal(prev => ({
                  ...prev,
                  show: false,
                }));
              }
            }}
            entityId={data?.id as number}
            message={
              'Sicuro di voler cancellare: ' + recordNameAccessor?.(data)
            }
          />
        ),
        title: `Cancella ${pageName}`,
        subtitle1: '',
      }));
    }
  };

  const editCol = {
    field: 'edit',
    headerName: 'Modifica',
    width: 100,
    cellRenderer: GriButton,
    cellRendererParams: {
      type: GRID_BUTTON_TYPE.EDIT,
      callback: callbackOnEdit,
    },
    pinned: 'right',
  } as ColDef;

  const deleteCol = {
    field: 'delete',
    headerName: 'Cancella',
    width: 100,
    cellRenderer: GriButton,
    cellRendererParams: {
      type: GRID_BUTTON_TYPE.DELETE,
      callback: callbackOnDelete,
    },
    pinned: 'right',
  } as ColDef;

  const handleGridReady = async (params: GridReadyEvent) => {
    params.api.showLoadingOverlay();
    const res = await handleFetchAll();
    if (!res || !res?.total) params.api.showNoRowsOverlay();
    else params.api.hideOverlay();
  };

  const onRowSelected = async (event: RowSelectedEvent) => {
    // if (event.node.isSelected()) {
  };

  const handleOpenNewForm = () => {
    setModal(prev => ({
      ...prev,
      show: true,
      children: <FormComponent />,
      title: `Registra ${pageName}`,
      subtitle1: '',
    }));
  };

  return (
    <>
      <Stack
        justifyContent={gridFilters ? 'space-between' : 'flex-end'}
        flexDirection={'row'}
        sx={{ marginBottom: '16px' }}
      >
        {gridFilters}
        {hasAddNew ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenNewForm}
          >
            Registra {pageName}
          </Button>
        ) : (
          <div />
        )}
      </Stack>
      <div
        className="ag-theme-quartz"
        style={{ height: 'calc(100vh - 170px)', width: '100%' }}
      >
        <AgGridReact
          rowData={dataSource || !rowData?.length ? [] : rowData}
          columnDefs={[
            ...columnDefs,
            ...(hasEdit ? [editCol] : []),
            ...(hasDelete ? [deleteCol] : []),
          ]}
          rowHeight={50}
          defaultColDef={{
            sortable: true,
            floatingFilter: true,
          }}
          animateRows={true}
          rowSelection="single"
          pagination={true}
          rowModelType={dataSource ? 'infinite' : 'clientSide'}
          datasource={dataSource}
          paginationPageSize={20}
          rowBuffer={0}
          cacheOverflowSize={2}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={20}
          maxBlocksInCache={10}
          onGridReady={handleGridReady}
          onRowSelected={onRowSelected}
          getRowId={(params: GetRowIdParams) => params.data.id}
          overlayLoadingTemplate={
            '<span class="ag-overlay-loading-center">loading...</span>'
          }
          overlayNoRowsTemplate={
            '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow">Non ci sono righe</span>'
          }
          {...rest}
        />
      </div>
    </>
  );
}
