import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import GridLayout from '../components/GridLayout';
import { EnteForm } from '../components/Forms';
import { useGrid } from '../hooks/useGrid';
import { Checkbox } from '@mui/material';
import {
  deleteEnteCallback,
  getAllEnteCallback,
  Ente,
  agenciesState,
} from '@laborability/commons';

export function EntesPage() {
  const getAllEntes = useRecoilCallback(getAllEnteCallback, []);
  const deleteEnte = useRecoilCallback(deleteEnteCallback, []);

  const { rowData, handleFetchAll, handleDelete } = useGrid<Ente>({
    state: agenciesState,
    onFetch: getAllEntes,
    onDelete: deleteEnte,
  });

  const [columnDefs, _] = useState<ColDef[]>([
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Ente',
    },
    {
      field: 'contract.name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Contratto',
    },
    {
      field: 'is_public_agency',
      flex: 2,
      filter: 'agNumberColumnFilter',
      filterParams: {
        maxNumConditions: 1,
        filterOptions: [
          {
            displayKey: 'Entrambi',
            displayName: 'Entrambi',
            predicate: () => true,
            numberOfInputs: 0,
          },
          {
            displayKey: 'Sì',
            displayName: 'Sì',
            // @ts-ignore
            predicate: (_, cellValue) => cellValue,
            numberOfInputs: 0,
          },
          {
            displayKey: 'No',
            displayName: 'No',
            // @ts-ignore
            predicate: (_, cellValue) => !cellValue,
            numberOfInputs: 0,
          },
        ],
        defaultOption: 'Entrambi',
      },
      headerName: 'Ente Pubblico',
      cellRenderer: (params: ICellRendererParams) => (
        <Checkbox checked={params.value} disabled />
      ),
    },
  ]);

  return (
    <GridLayout<Ente>
      pageName="Ente"
      columnDefs={columnDefs}
      rowData={rowData}
      handleFetchAll={handleFetchAll}
      handleDelete={handleDelete}
      FormComponent={EnteForm}
      recordNameAccessor={item => item.name ?? ''}
    />
  );
}
