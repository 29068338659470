import { ColDef } from 'ag-grid-community';
import { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import GridLayout from '../components/GridLayout';
import { BenefitForm } from '../components/Forms';
import { useGrid } from '../hooks/useGrid';
import {
  getAllBenefitsCallback,
  deleteBenefitCallback,
  Benefit,
  benefitsState,
} from '@laborability/commons';

export function BenefitsPage() {
  const getAllBenefits = useRecoilCallback(getAllBenefitsCallback, []);
  const deleteBenefit = useRecoilCallback(deleteBenefitCallback, []);

  const { rowData, handleFetchAll, handleDelete } = useGrid<Benefit>({
    state: benefitsState,
    onFetch: getAllBenefits,
    onDelete: deleteBenefit,
  });

  const [columnDefs, _] = useState<ColDef[]>([
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Benefit',
    },
    {
      field: 'description',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Descrizione',
    },
  ]);

  return (
    <GridLayout<Benefit>
      pageName="Benefit"
      columnDefs={columnDefs}
      rowData={rowData}
      handleFetchAll={handleFetchAll}
      handleDelete={handleDelete}
      FormComponent={BenefitForm}
      recordNameAccessor={item => item.name ?? ''}
    />
  );
}
