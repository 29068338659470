import { ColDef } from 'ag-grid-community';
import { useState } from 'react';
import { useRecoilCallback } from 'recoil';
import GridLayout from '../components/GridLayout';
import { ContractForm } from '../components/Forms';
import { useGrid } from '../hooks/useGrid';
import {
  getAllContractsCallback,
  deleteContractCallback,
  Contract,
  contractsState,
} from '@laborability/commons';

export function ContractsPage() {
  const getAllContracts = useRecoilCallback(getAllContractsCallback, []);
  const deleteContract = useRecoilCallback(deleteContractCallback, []);

  const { rowData, handleFetchAll, handleDelete } = useGrid<Contract>({
    state: contractsState,
    onFetch: getAllContracts,
    onDelete: deleteContract,
  });

  const [columnDefs, _] = useState<ColDef[]>([
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    {
      field: 'name',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Contract',
    },
  ]);

  return (
    <GridLayout<Contract>
      pageName="Contract"
      columnDefs={columnDefs}
      rowData={rowData}
      handleFetchAll={handleFetchAll}
      handleDelete={handleDelete}
      FormComponent={ContractForm}
      recordNameAccessor={item => item.name ?? ''}
    />
  );
}
