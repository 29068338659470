import { ColDef } from 'ag-grid-community';
import { useState } from 'react';
import { useRecoilCallback, useSetRecoilState } from 'recoil';
import GridLayout from '../components/GridLayout';
import { TagForm } from '../components/Forms';
import { useGrid } from '../hooks/useGrid';
import {
  getAllTagsCallback,
  deleteTagCallback,
  Tag,
  tagsState,
} from '@laborability/commons';
import { GridImage } from '../components/GridImage';
import { ImageView } from '../components/ImageView';
import { modalState } from '../recoil/states/modalState';

export function TagsPage() {
  const setModal = useSetRecoilState(modalState);
  const getAllTags = useRecoilCallback(getAllTagsCallback, []);
  const deleteTag = useRecoilCallback(deleteTagCallback, []);

  const { rowData, handleFetchAll, handleDelete } = useGrid<Tag>({
    state: tagsState,
    onFetch: getAllTags,
    onDelete: deleteTag,
  });

  const [columnDefs, _] = useState<ColDef[]>([
    {
      field: 'id',
      maxWidth: 100,
      filter: 'agNumberColumnFilter',
      resizable: false,
      headerName: 'ID',
    },
    { field: 'name', flex: 2, filter: 'agTextColumnFilter', headerName: 'Tag' },
    {
      field: 'description',
      flex: 2,
      filter: 'agTextColumnFilter',
      headerName: 'Descrizione',
    },
    {
      field: 'image',
      filter: false,
      flex: 1,
      headerName: 'Immagine',
      width: 100,
      cellRenderer: GridImage,
      cellRendererParams: {
        callback: async (data: Tag) => {
          setModal(prev => ({
            ...prev,
            show: true,
            children: <ImageView image={data?.image ?? ''} />,
            title: 'Immagine Tag',
          }));
        },
      },
    },
  ]);

  return (
    <GridLayout<Tag>
      pageName="TAG"
      columnDefs={columnDefs}
      rowData={rowData}
      handleFetchAll={handleFetchAll}
      handleDelete={handleDelete}
      FormComponent={TagForm}
      recordNameAccessor={item => item.name ?? ''}
      rowHeight={100}
    />
  );
}
